import React from 'react';
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy';
import Layout from '~/components/Layout';
import Metadata from '~/components/Metadata';
import ErrorNotFoundPage from '~/components/Error/NotFound/Page';

const NotFound = () => {
  const translate = useTranslate();

  return (
    <Layout>
      <Metadata title={translate('error.not_found.page_title')} />
      <ErrorNotFoundPage />
    </Layout>
  );
};

export default NotFound;
